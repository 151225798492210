import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import StaticImage from "../../../components/StaticImage";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { PaginationBullet } from "../../../components/PaginationBullet";
import styled from "@emotion/styled";

SwiperCore.use([Pagination]);

const SwiperComponent = styled(Swiper)`
  margin-bottom: 3rem;
`;

const SlideImage = styled(StaticImage)`
  margin: 0 !important;
`;

const FuturpreneurCampaignStandardsCarousel = () => {
  const data = useStaticQuery(query);
  return (
    <SwiperComponent
      loop={true}
      pagination={{
        clickable: true,
        renderBullet: PaginationBullet,
        bulletClass: "carousel-bullet-gold",
        bulletActiveClass: "carousel-bullet-gold--active",
      }}
    >
      <SwiperSlide>
        <SlideImage image={data.slide1} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideImage image={data.slide2} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideImage image={data.slide3} />
      </SwiperSlide>
    </SwiperComponent>
  );
};

const query = graphql`
  fragment File on File {
    name
    extension
    publicURL
    relativePath
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }

  {
    slide1: file(
      relativePath: {
        eq: "case-studies/futurpreneur/campaignstandards/Standards_1.png"
      }
    ) {
      ...File
    }
    slide2: file(
      relativePath: {
        eq: "case-studies/futurpreneur/campaignstandards/Standards_2.png"
      }
    ) {
      ...File
    }
    slide3: file(
      relativePath: {
        eq: "case-studies/futurpreneur/campaignstandards/Standards_3.png"
      }
    ) {
      ...File
    }
  }
`;

export default FuturpreneurCampaignStandardsCarousel;
