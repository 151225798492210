import React, { useContext } from "react";
import parse from "html-react-parser";
import ShortcodeParser from "./ShortcodeParser";
import styled from "@emotion/styled";
import shortcodeMap from "./shortcodeMapping";
import SubsectionContext from "../../../contexts/SubsectionContext";

const MainContainer = styled.div`
  margin-bottom: 2rem;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 3rem;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .mobile-only {
    display: block;
    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      display: none;
    }
  }
  .desktop-only {
    display: none;
    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      display: block;
    }
  }
  .d-flex {
    display: flex;
  }
  .align-items-center {
    align-items: center;
  }
  .flex-direction {
    &-row {
      flex-direction: row;
    }
    &-column {
      flex-direction: column;
    }
  }
  .w {
    &-100 {
      width: 100%;
    }
  }
  .width-6 {
    width: 50%;
    &-mobile-only {
      width: 50%;
      @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
        width: 100%;
      }
    }
    &-desktop-only {
      width: 100%;
      @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
        width: 50%;
      }
    }
  }
  .p3 {
    padding: 3rem;
  }
  img.full-width,
  figure,
  .gatsby-image-wrapper {
    width: 100% !important;
  }
`;

const ContentContainer = styled.div`
  h1 {
    font-size: 4rem;
    line-height: 5rem;
    font-family: ${props => props.theme.fonts.medium};
    font-weight: 500;
    margin-bottom: 3rem;
    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 4.8rem;
      line-height: 6rem;
    }
    .content--lightPeach & {
      color: ${props => props.theme.colors.offBlack};
    }
    .content--offWhite & {
      color: ${props => props.theme.colors.offBlack};
    }
    .content--darkestPurple & {
      color: ${props => props.theme.colors.offWhite};
    }
  }

  h2 {
    font-size: 2.5rem;
    line-height: 3.2rem;
    font-family: ${props => props.theme.fonts.medium};
    font-weight: 500;
    margin-bottom: 2rem;
    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 3.1rem;
      line-height: 3.8rem;
    }
    .content--lightPeach & {
      color: ${props => props.theme.colors.darkerPurple};
    }
    .content--offWhite & {
      color: ${props => props.theme.colors.darkerPurple};
    }
    .content--darkestPurple & {
      color: ${props => props.theme.colors.offWhite};
    }
  }
  h3 {
    font-size: 2.3rem;
    line-height: 3.7rem;
    font-family: ${props => props.theme.fonts.regular};
    margin-bottom: 2rem;
    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.6rem;
      line-height: 4.1rem;
      margin-bottom: 3rem;
    }
    &.gold {
      color: ${props => props.theme.colors.darkGold} !important;
    }
    .content--lightPeach & {
      color: ${props => props.theme.colors.darkGold};
    }
    .content--offWhite & {
      color: ${props => props.theme.colors.darkGold};
    }
    .content--darkestPurple & {
      color: ${props => props.theme.colors.offWhite};
    }
  }
  h4 {
    font-size: 2.3rem;
    line-height: 3.7rem;
    font-family: ${props => props.theme.fonts.medium};
    margin-bottom: 2.6rem;
    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.6rem;
      line-height: 4.1rem;
      margin-bottom: 4rem;
    }
    .content--lightPeach & {
      color: ${props => props.theme.colors.darkGold};
    }
    .content--offWhite & {
      color: ${props => props.theme.colors.darkGold};
    }
    .content--darkestPurple & {
      color: ${props => props.theme.colors.offWhite};
    }
  }
  h5 {
    font-size: 1.5rem;
    line-height: 2.5rem;
    font-family: ${props => props.theme.fonts.light};
    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.7rem;
      line-height: 3.7rem;
    }
    .content--lightPeach & {
      color: ${props => props.theme.colors.mediumTeal};
    }
    .content--offWhite & {
      color: ${props => props.theme.colors.mediumTeal};
    }
    .content--darkestPurple & {
      color: ${props => props.theme.colors.offWhite};
    }
  }
  p {
    font-size: 1.8rem;
    line-height: 2.9rem;
    font-family: ${props => props.theme.fonts.regular};
    margin-bottom: 2rem;
    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      font-family: ${props => props.theme.fonts.light};
    }
    &:last-child {
      margin-bottom: 0;
      // > img {
      //   padding-top: 2rem;
      //   padding-bottom: 0;
      // }
    }
    .content--lightPeach & {
      color: ${props => props.theme.colors.offBlack};
    }
    .content--offWhite & {
      color: ${props => props.theme.colors.offBlack};
    }
    .content--darkestPurple & {
      color: ${props => props.theme.colors.offWhite};
    }
    > img {
      padding: 3rem 0;
      @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
        padding: 5rem 0;
      }
    }
  }
  ol {
    padding: 2rem 0 0;
    list-style: none;
    counter-reset: section;
    .content--lightPeach & {
      color: ${props => props.theme.colors.offBlack};
    }
    .content--offWhite & {
      color: ${props => props.theme.colors.offBlack};
    }
    .content--darkestPurple & {
      color: ${props => props.theme.colors.offWhite};
    }
    li {
      margin-bottom: 3rem;
      font-family: ${props => props.theme.fonts.medium};
      font-size: 1.8rem;
      line-height: 2.9rem;
      letter-spacing: 0.54px;
      display: flex;
      align-items: center;
      counter-increment: section;
      @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
        font-size: 2.5rem;
        line-height: 3.2rem;
        letter-spacing: 0.75px;
      }
      &::before {
        content: counter(section);
        display: inline-flex;
        flex: 0 0 auto;
        color: white;
        width: 3.8rem;
        background: ${props => props.theme.colors.darkGold};
        height: 3.8rem;
        margin-right: 2rem;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
          width: 4.8rem;
          height: 4.8rem;
          margin-right: 2.4rem;
        }
        .content--lightPeach & {
          background: ${props => props.theme.colors.darkGold};
        }
        .content--offWhite & {
          background: ${props => props.theme.colors.darkGold};
        }
        .content--darkestPurple & {
          background: ${props => props.theme.colors.lightestPurple};
        }
      }
      h4 {
        margin-bottom: 0;
      }
      p {
        margin-top: 4rem;
      }
    }
  }
  ul {
    li {
      font-size: 1.8rem;
      line-height: 2.9rem;
      font-family: ${props => props.theme.fonts.regular};
      margin-bottom: 2rem;
      @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
        font-family: ${props => props.theme.fonts.light};
      }
      // &:last-child {
      //   margin-bottom: 0;
      // > img {
      //   padding-top: 2rem;
      //   padding-bottom: 0;
      // }
      // }
      .content--lightPeach & {
        color: ${props => props.theme.colors.offBlack};
      }
      .content--offWhite & {
        color: ${props => props.theme.colors.offBlack};
      }
      .content--darkestPurple & {
        color: ${props => props.theme.colors.offWhite};
      }
      > img {
        padding: 3rem 0;
        @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
          padding: 5rem 0;
        }
      }
    }
  }
  figure {
    margin-bottom: 5rem;
    figcaption {
      margin-top: 4rem;
      color: ${props => props.theme.colors.darkGold};
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
  }
  .gatsby-image-wrapper {
    &.with-spacing {
      margin: 3rem 0;
      @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
        margin: 6rem 0;
      }
    }
  }
`;

const replaceHtmlElementWithReactComponent = (node, shortcodeMapping) => {
  for (const code in shortcodeMapping) {
    if (node.name === code) {
      return shortcodeMapping[code];
    }
  }
};

const Wysiwyg = props => {
  const subsection = useContext(SubsectionContext);
  const { markup } = ShortcodeParser(props.content);
  const jsxOutput = parse(markup, {
    replace: node => replaceHtmlElementWithReactComponent(node, shortcodeMap),
  });
  return (
    <MainContainer className={`content--${subsection.backgroundColorName}`}>
      <ContentContainer>{jsxOutput}</ContentContainer>
    </MainContainer>
  );
};

export default Wysiwyg;
