import styled from "@emotion/styled";
import React, { useContext } from "react";
import SubsectionContext from "../../../contexts/SubsectionContext";

const ListContainer = styled.div`
  margin-bottom: 2rem;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 3rem;
  }
`;

const ListInnerContainer = styled.div``;

const ListTag = styled.ol`
  padding: 0;
  list-style: none;
  counter-reset: section;
  .content--lightPeach & {
    color: ${props => props.theme.colors.offBlack};
  }
  .content--offWhite & {
    color: ${props => props.theme.colors.offBlack};
  }
  .content--darkestPurple & {
    color: ${props => props.theme.colors.offWhite};
  }
`;

const ListItem = styled.li`
  margin-bottom: 2rem;
  font-family: ${props => props.theme.fonts.medium};
  font-size: 1.8rem;
  line-height: 2.9rem;
  letter-spacing: 0.54px;
  display: flex;
  align-items: flex-start;
  counter-increment: section;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 2.5rem;
    line-height: 3.2rem;
    letter-spacing: 0.75px;
  }
  &::before {
    content: counter(section);
    display: inline-flex;
    flex: 0 0 auto;
    color: white;
    width: 3.8rem;
    background: ${props =>
      props.markerBackgroundColor || props.theme.colors.darkerPurple};
    height: 3.8rem;
    margin-right: 2rem;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      width: 4.8rem;
      height: 4.8rem;
      margin-right: 2.4rem;
    }
  }
`;

const ListItemContentWrapper = styled.div``;

const ListItemHeading = styled.h3`
  font-size: 2.3rem;
  line-height: 3.7rem;
  font-family: ${props => props.theme.fonts.regular};
  margin-bottom: 2rem;
  color: ${props => props.color};
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 2.6rem;
    line-height: 4.1rem;
    margin-bottom: 1rem;
  }
`;

const ListItemContent = styled.div`
  p {
    font-size: 1.8rem;
    line-height: 2.9rem;
    font-family: ${props => props.theme.fonts.regular};
    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      font-family: ${props => props.theme.fonts.light};
    }
  }
`;

const ListComponent = data => {
  const context = useContext(SubsectionContext);
  return (
    <ListContainer>
      <ListInnerContainer className={`content--${context.backgroundColorName}`}>
        <ListTag>
          {data.listItems.map((item, i) => (
            <ListItem
              key={i}
              markerBackgroundColor={data.markerBackgroundColor}
            >
              <ListItemContentWrapper>
                {item?.heading && (
                  <ListItemHeading color={data?.listItemHeadingColor}>
                    {item.heading}
                  </ListItemHeading>
                )}
                {item?.content && (
                  <ListItemContent
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                )}
              </ListItemContentWrapper>
            </ListItem>
          ))}
        </ListTag>
      </ListInnerContainer>
    </ListContainer>
  );
};

export default ListComponent;
