import * as React from "react";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { PaginationBullet } from "../../../components/PaginationBullet";
import styled from "@emotion/styled";

SwiperCore.use([Pagination]);

const SwiperComponent = styled(Swiper)`
  margin-bottom: 3rem;
  .swiper-slide {
    height: auto !important;
  }
`;

const Slide = styled.div`
  background-color: ${props => props.theme.colors.darkPeach};
  padding: 3rem 4.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const QuoteContent = styled.q`
  font-size: 1.8rem;
  line-height: 2.5rem;
  font-family: ${props => props.theme.fonts.italic};
  margin-bottom: 2rem;
  color: ${props => props.theme.colors.darkTeal};
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    font-family: ${props => props.theme.fonts.italic};
  }
`;

const QuoteAuthor = styled.p`
  color: ${props => props.theme.colors.darkGold}!important;
  margin-bottom: 3rem !important;
`;

const BrandishTeamQuoteCarousel = () => {
  return (
    <SwiperComponent
      loop={true}
      pagination={{
        clickable: true,
        renderBullet: PaginationBullet,
        bulletClass: "carousel-bullet-gold",
        bulletActiveClass: "carousel-bullet-gold--active",
      }}
    >
      <SwiperSlide>
        <Slide>
          <QuoteContent>
            Re-developing our brand was important. As a team, we had evolved so
            much since we developed the first incarnation of the Brandish brand.
            The fabric of who we were and what we were had changed significantly
            since we first started the agency. Our brand is the gateway to our
            work and it was important for us to create something compelling that
            would attract people who ultimately aligned with the promise of who
            we are and what we are here to do. I’m excited with what our team
            created and I’m even more excited to continue to watch it evolve.
          </QuoteContent>
          <QuoteAuthor>- Lee Waltham, Marketing Director</QuoteAuthor>
        </Slide>
      </SwiperSlide>
      <SwiperSlide>
        <Slide>
          <QuoteContent>
            It has always been our goal to be a living artifact of what we
            define and deliver to our clients — a brand. The same process of
            industry analysis and positioning we have done here for ourselves.
            There is an irony to the near invisibility of brand agencies in the
            public consciousness that we hope to address. We want to be known
            and recognized as the brand that makes great brands.
          </QuoteContent>
          <QuoteAuthor>- Morley MacDougall, Creative Director</QuoteAuthor>
        </Slide>
      </SwiperSlide>
      <SwiperSlide>
        <Slide>
          <QuoteContent>
            Our new website technology stack is an example of our attention to
            the details of the brand experience. For the new brand portal, our
            developer team identified an opportunity to work with emerging
            technology that means super fast load times without sacrificing user
            experience.
          </QuoteContent>
          <QuoteAuthor>- Danny Wood, Director of Product</QuoteAuthor>
        </Slide>
      </SwiperSlide>
      <SwiperSlide>
        <Slide>
          <QuoteContent>
            We believe that what we do is about people–the customers that brands
            want to reach. To reflect this central belief we set out to create a
            brand identity system that felt human and was an authentic
            reflection of who we are. Not an averaging of our extremes, but a
            fluid and flexible representation of the widely varying aspects of
            what makes Brandish, Brandish.
          </QuoteContent>
          <QuoteAuthor>- Candice Twomey, Art Director</QuoteAuthor>
        </Slide>
      </SwiperSlide>
      <SwiperSlide>
        <Slide>
          <QuoteContent>
            Brandish 2.0 is a flexible identity that falls under the three main
            brand values of being Bold, Empathetic, and Intelligent. With two
            different illustration styles and the way we developed our
            photography treatment and infographic style, the identity has the
            ability to be more experimental and dynamic depending on what
            personality trait we’re leaning into. Our new visual identity system
            shows the diverse offerings of our agency while demonstrating our
            unique talents and in-depth work we do for our clients—but now for
            ourselves.
          </QuoteContent>
          <QuoteAuthor>
            - Alex Fisher, Graphic Designer + Illustrator
          </QuoteAuthor>
        </Slide>
      </SwiperSlide>
      <SwiperSlide>
        <Slide>
          <QuoteContent>
            Our brand update was an opportunity to explore and push the limits
            of what a brand can do. We contemplated every facet of our brand in
            order to create a dynamic and expansive brand personality and voice.
            It was important for us to create a brand that our audience could
            easily connect with and we found inspiration from a likely source
            —us, humans. A brand can’t be one thing, it needs to be dynamic and
            adaptable, like a human personality. That’s why it was so important
            to build context around our brand and be human-like.
          </QuoteContent>
          <QuoteAuthor>- Cleo Curtis, Communications Manager</QuoteAuthor>
        </Slide>
      </SwiperSlide>
      <SwiperSlide>
        <Slide>
          <QuoteContent>
            As much as we emphasize that brand is about people, we’ve also
            started to operate using this metaphor of brand as a person. Yes,
            there’s this face to a brand— a logo or wordmark— that many people
            erroneously understand synonymous with brand identity, but brand can
            really be the idea of this person as a whole. The character, the
            values they uphold, the way they interact with others is really at
            the heart of branding. Brand is also always changing and evolving
            and we wanted to incorporate this idea of dynamism into the Brandish
            identity. Brand isn’t built around a static set of rules but it’s
            actions, contributions and character that help define its value to
            the world.
          </QuoteContent>
          <QuoteAuthor>- Kyle Doucette, Art Director</QuoteAuthor>
        </Slide>
      </SwiperSlide>
    </SwiperComponent>
  );
};

export default BrandishTeamQuoteCarousel;
