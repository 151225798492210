import * as React from "react";
import Wysiwyg from "../../content-blocks/Wysiwyg";
import Timeline from "../../content-blocks/Timeline";
import List from "../../content-blocks/List";

export const contentBlockMapping = {
  Case_study_Casestudybuilder_sections_subsections_ContentBlocks_Wysiwyg: (
    <Wysiwyg />
  ),
  Case_study_Casestudybuilder_sections_subsections_ContentBlocks_Timeline: (
    <Timeline />
  ),
  Case_study_Casestudybuilder_sections_subsections_ContentBlocks_List: <List />,
};
