import * as React from "react";
import StaticImage from "../../../components/StaticImage";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { PaginationBullet } from "../../../components/PaginationBullet";
import styled from "@emotion/styled";
import Desktop1 from "../../../icons/case-studies/form/research-carousel/Research_Carousel-1.svg";
import Desktop2 from "../../../icons/case-studies/form/research-carousel/Research_Carousel-2.svg";
import Desktop3 from "../../../icons/case-studies/form/research-carousel/Research_Carousel-3.svg";
import Desktop4 from "../../../icons/case-studies/form/research-carousel/Research_Carousel-4.svg";
import Mobile1 from "../../../icons/case-studies/form/research-carousel/Research_Carousel-1_Mobile.svg";
import Mobile2 from "../../../icons/case-studies/form/research-carousel/Research_Carousel-2_Mobile.svg";
import Mobile3 from "../../../icons/case-studies/form/research-carousel/Research_Carousel-3_Mobile.svg";
import Mobile4 from "../../../icons/case-studies/form/research-carousel/Research_Carousel-4_Mobile.svg";

SwiperCore.use([Pagination]);

const SwiperComponent = styled(Swiper)`
  margin-bottom: 3rem;
`;

const SwiperWrapperMobile = styled.div`
  display: block;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`;

const SwiperWrapperDesktop = styled.div`
  display: none;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    display: block;
  }
`;

const SlideImage = styled(StaticImage)`
  width: 100%;
  margin: 0 !important;
`;

const FormResearchCarousel = () => {
  return (
    <>
      <SwiperWrapperMobile>
        <SwiperComponent
          loop={true}
          pagination={{
            clickable: true,
            renderBullet: PaginationBullet,
            bulletClass: "carousel-bullet-gold",
            bulletActiveClass: "carousel-bullet-gold--active",
          }}
        >
          <SwiperSlide>
            <SlideImage image={<Mobile1 />} />
          </SwiperSlide>
          <SwiperSlide>
            <SlideImage image={<Mobile2 />} />
          </SwiperSlide>
          <SwiperSlide>
            <SlideImage image={<Mobile3 />} />
          </SwiperSlide>
          <SwiperSlide>
            <SlideImage image={<Mobile4 />} />
          </SwiperSlide>
        </SwiperComponent>
      </SwiperWrapperMobile>
      <SwiperWrapperDesktop>
        <SwiperComponent
          loop={true}
          pagination={{
            clickable: true,
            renderBullet: PaginationBullet,
            bulletClass: "carousel-bullet-gold",
            bulletActiveClass: "carousel-bullet-gold--active",
          }}
        >
          <SwiperSlide>
            <SlideImage image={<Desktop1 />} />
          </SwiperSlide>
          <SwiperSlide>
            <SlideImage image={<Desktop2 />} />
          </SwiperSlide>
          <SwiperSlide>
            <SlideImage image={<Desktop3 />} />
          </SwiperSlide>
          <SwiperSlide>
            <SlideImage image={<Desktop4 />} />
          </SwiperSlide>
        </SwiperComponent>
      </SwiperWrapperDesktop>
    </>
  );
};

export default FormResearchCarousel;
