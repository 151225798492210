import React from "react";
import styled from "@emotion/styled";
import CaseStudySubsection from "./CaseStudySubsection";

const SectionContainer = styled.section``;

const CaseStudySection = ({ isFirstSection, data }) => {
  // React.useEffect(() => {
  //   console.log(data);
  // }, [data]);

  const caseStudySubsections = data?.subsections?.map((subsection, index) => (
    <CaseStudySubsection
      isFirstSection={isFirstSection}
      isFirstSubsection={index === 0}
      key={index}
      data={subsection}
      sectionData={data}
    />
  ));

  return (
    <SectionContainer id={data?.sectionType}>
      {caseStudySubsections}
    </SectionContainer>
  );
};

export default CaseStudySection;
