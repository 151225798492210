import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import CaseStudyContainer from "../../components/case-study-general/CaseStudyContainer";
import { contentBlockMapping } from "./contentBlockMapping";
import ContentContext from "../../../contexts/ContentContext";
import TitleLine from "../../components/case-study-general/CaseStudyTitleLine";
import Descriptor from "../../components/Descriptor";
import Image from "../../components/image";
import { SubsectionProvider } from "../../../contexts/SubsectionContext";
import { getKeyByValue } from "../../shared/helpers";
import { useTheme } from "@emotion/react";

const SubsectionContainer = styled.div`
  background-color: ${props => props.backgroundColor};
  padding: 4.2rem 1.5rem 5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 7rem 12rem;
  }
`;

const SectionInfoContainer = styled.div``;

const SectionHeading = styled.h1`
  font-family: ${props => props.theme.fonts.medium};
  font-size: 4.8rem;
  line-height: 60px;
  color: ${props => props.theme.colors.offBlack};
  margin-bottom: 3rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-weight: 500;
    width: 100%;
    font-size: 6.5rem;
    line-height: 80px;
    text-align: center;
    margin: 0 auto 2rem;
  }
`;

const SubsectionHeadingContainer = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 650px;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    /* margin-right: 12.586206896%; */
    margin-left: 23.189655172%;
    max-width: 750px;
  }
`;

const SubsectionHeading = styled.h2`
  font-family: ${props => props.theme.fonts.medium};
  font-size: 3.1rem;
  line-height: 38px;
  color: ${props => props.theme.colors.offWhite};
  margin-bottom: 2.5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 4.8rem;
    line-height: 60px;
    margin-bottom: 4.5rem;
  }
`;

const SubsectionContentWrapper = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
  }
`;

const SubsectionDescriptorWrapper = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 23.275862069%;
    padding: 0 6rem;
  }
`;

const SubsectionContentBlockWrapper = styled.article`
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 650px;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    /* width: 62.5%; */
    max-width: 750px;
    margin-left: ${props => (props.isFirstSubsection ? "0" : "23.189655172%")};
  }
`;

const DescriptorImage = styled(Image)`
  width: 5.45rem;
  height: 5.45rem;
  margin: 3rem 0;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 3rem auto;
  }
`;

const CaseStudyInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const CaseStudyInfoItem = styled.h3`
  font-family: ${props => props.theme.fonts.light};
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 32px;
  color: ${props => props.theme.colors.offBlack};

  span {
    font-family: ${props => props.theme.fonts.medium};
    font-weight: 500;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: inline-block;
    margin-right: 5rem;
    padding-top: 1.25rem;
  }
`;

const CaseStudySubsection = ({
  isFirstSection,
  isFirstSubsection,
  data,
  sectionData,
  ...props
}) => {
  const content = useContext(ContentContext);
  const theme = useTheme();
  const subsectionBackgroundColor = `${data?.backgroundColor}`?.toUpperCase();
  const subsectionBackgroundColorName = getKeyByValue(
    theme.colors,
    `${data?.backgroundColor}`?.toUpperCase()
  );
  const subsectionContentBlocks = data?.contentBlocks?.map(
    (block, index) =>
      contentBlockMapping[block.fieldGroupName] &&
      React.cloneElement(contentBlockMapping[block.fieldGroupName], {
        ...block,
        key: index,
      })
  );
  return (
    <SubsectionProvider
      value={{
        backgroundColor: subsectionBackgroundColor,
        backgroundColorHexcode: subsectionBackgroundColor,
        backgroundColorName: subsectionBackgroundColorName,
      }}
    >
      <SubsectionContainer
        className={`subsection subsection--${subsectionBackgroundColorName}`}
        backgroundColor={subsectionBackgroundColor}
      >
        <CaseStudyContainer>
          {isFirstSubsection && (
            <SectionInfoContainer>
              <SectionHeading>{sectionData.title}</SectionHeading>
              <TitleLine />
            </SectionInfoContainer>
          )}
          {data?.hasTitle && data?.title && (
            <SubsectionHeadingContainer>
              <SubsectionHeading>{data?.title}</SubsectionHeading>
            </SubsectionHeadingContainer>
          )}
          <SubsectionContentWrapper>
            {isFirstSubsection && (
              <SubsectionDescriptorWrapper>
                {sectionData?.descriptor && (
                  <Descriptor
                    title={sectionData?.descriptor}
                    color={sectionData?.descriptorColor}
                    textAlign="center"
                    alignment={sectionData?.underlineAlignment}
                  />
                )}
                {sectionData?.icon && (
                  <DescriptorImage image={sectionData?.icon} />
                )}
              </SubsectionDescriptorWrapper>
            )}
            {data?.hasDescriptor && data?.descriptor?.descriptor && (
              <SubsectionDescriptorWrapper>
                {data?.descriptor?.descriptor && (
                  <Descriptor
                    title={data?.descriptor?.descriptor}
                    color={data?.descriptor?.descriptorColor}
                    textAlign="center"
                    alignment={data?.descriptor?.underlineAlignment}
                  />
                )}
                {data?.descriptor?.icon && (
                  <DescriptorImage image={data?.descriptor?.icon} />
                )}
              </SubsectionDescriptorWrapper>
            )}
            <SubsectionContentBlockWrapper
              isFirstSubsection={isFirstSubsection || data?.hasDescriptor}
            >
              {isFirstSection && isFirstSubsection && (
                <CaseStudyInfoContainer>
                  {content?.CaseStudyBuilder?.partner && (
                    <CaseStudyInfoItem>
                      <span>Partner:</span> {content?.CaseStudyBuilder?.partner}
                    </CaseStudyInfoItem>
                  )}
                  {content?.CaseStudyBuilder?.role && (
                    <CaseStudyInfoItem>
                      <span>Our Role:</span> {content?.CaseStudyBuilder?.role}
                    </CaseStudyInfoItem>
                  )}
                </CaseStudyInfoContainer>
              )}
              {subsectionContentBlocks}
            </SubsectionContentBlockWrapper>
          </SubsectionContentWrapper>
        </CaseStudyContainer>
      </SubsectionContainer>
    </SubsectionProvider>
  );
};

CaseStudySubsection.propTypes = {
  isFirstSection: PropTypes.bool.isRequired,
  isFirstSubsection: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  sectionData: PropTypes.object.isRequired,
};

export default CaseStudySubsection;
