import * as React from "react";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { PaginationBullet } from "../../../components/PaginationBullet";
import styled from "@emotion/styled";

SwiperCore.use([Pagination]);

const SwiperComponent = styled(Swiper)`
  margin-bottom: 3rem;
`;

const SwiperText = styled.div`
  &:hover {
    cursor: grab;
  }
  margin-bottom: 6rem;
  .teal-text {
    color: ${props => props.theme.colors.mediumTeal} !important;
    font-size: 3.5rem;
    font-family: ${props => props.theme.fonts.medium};
    line-height: 42px;
  }
  .white-text {
    color: ${props => props.theme.colors.white} !important;
    font-size: 2.2rem;
    font-family: ${props => props.theme.fonts.regular};
    line-height: 42px;
  }
  .pink-text {
    color: ${props => props.theme.colors.lightPink} !important;
    font-size: 3.5rem;
    font-family: ${props => props.theme.fonts.medium};
    line-height: 42px;
  }
`;

const BrandishAgentCarousel = () => {
  return (
    <SwiperComponent
      loop={true}
      pagination={{
        clickable: true,
        renderBullet: PaginationBullet,
        bulletClass: "carousel-bullet-gold",
        bulletActiveClass: "carousel-bullet-gold--active",
      }}
    >
      <SwiperSlide>
        <SwiperText>
          <p className="teal-text">
            Because the Change Agent needs to cover all their bases...
          </p>
          <p className="white-text">
            (so they know that they are making the most complete and compelling
            choice to address their goals and needs.)
          </p>
          <p className="pink-text">...the experience must be comprehensive.</p>
        </SwiperText>
      </SwiperSlide>
      <SwiperSlide>
        <SwiperText>
          <p className="teal-text">
            Because the Change Agent needs clear insight and understandings...
          </p>
          <p className="white-text">
            (so they can make sense of complex choices and understand the
            solution in actionable items)
          </p>
          <p className="pink-text">...the experience must be insightful.</p>
        </SwiperText>
      </SwiperSlide>
      <SwiperSlide>
        <SwiperText>
          <p className="teal-text">
            Because the Change Agent needs to make the best investment for their
            organization...
          </p>
          <p className="white-text">
            (so the can keep driving towards their goals)
          </p>
          <p className="pink-text">...the experience must be original.</p>
        </SwiperText>
      </SwiperSlide>
      <SwiperSlide>
        <SwiperText>
          <p className="teal-text">
            Because the Change Agent needs creative and innovative solutions...
          </p>
          <p className="white-text">
            (so they can achieve their organizational goals and stay competitive
            within their industry)
          </p>
          <p className="pink-text">...the experience must be valuable</p>
        </SwiperText>
      </SwiperSlide>
    </SwiperComponent>
  );
};

export default BrandishAgentCarousel;
