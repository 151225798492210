import * as React from "react";
import StaticImage from "../../../components/StaticImage";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { PaginationBullet } from "../../../components/PaginationBullet";
import styled from "@emotion/styled";
import Desktop1 from "../../../icons/case-studies/form/visual-identity-fonts-carousel/Visual-Identity_Carousel-Fonts-1.svg";
import Desktop2 from "../../../icons/case-studies/form/visual-identity-fonts-carousel/Visual-Identity_Carousel-Fonts-2.svg";

SwiperCore.use([Pagination]);

const SwiperComponent = styled(Swiper)`
  margin-bottom: 3rem;
`;

const SlideImage = styled(StaticImage)`
  width: 100%;
  margin: 0 !important;
`;
const FormVisualIdentityFontsCarousel = () => {
  return (
    <SwiperComponent
      loop={true}
      pagination={{
        clickable: true,
        renderBullet: PaginationBullet,
        bulletClass: "carousel-bullet-gold",
        bulletActiveClass: "carousel-bullet-gold--active",
      }}
    >
      <SwiperSlide>
        <SlideImage image={<Desktop1 />} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideImage image={<Desktop2 />} />
      </SwiperSlide>
    </SwiperComponent>
  );
};

export default FormVisualIdentityFontsCarousel;
