import React, { useState } from "react";
import Layout from "../../components/layout";
import Hero from "../../components/Hero";
import { ContentProvider } from "../../../contexts/ContentContext";
import CaseStudySection from "./CaseStudySection";
import styled from "@emotion/styled";
import CaseStudyFooter from "../../components/case-study-general/CaseStudyFooter";
import CaseStudyNavigation from "../../components/case-study-general/CaseStudyNavigation";
import { capitalize } from "../../shared/helpers";

const CaseStudyContainer = styled.div``;

const CaseStudy = ({ pageContext }) => {
  // console.log(pageContext.caseStudy.CaseStudyBuilder.sections);
  const {
    caseStudy: {
      id,
      databaseId,
      CaseStudy,
      CaseStudyBuilder,
      featuredImage,
      FeaturedImages,
      nodeType,
      HeroSection,
      PageCptFields,
      PreFooter,
      seo,
      slug,
      status,
      title,
      uri,
    },
  } = pageContext;
  const [hidenav, setNav] = useState(false);
  function setVisibility(isVisible) {
    if (isVisible) {
      setNav(true);
    } else {
      setNav(false);
    }
  }

  const caseStudySections = CaseStudyBuilder?.sections?.map(
    (section, index) => (
      <CaseStudySection
        isFirstSection={index === 0}
        key={index}
        data={section}
      />
    )
  );

  const caseStudySectionNavItems = CaseStudyBuilder?.sections?.map(section => {
    return {
      id: section.sectionType,
      target: `#${section.sectionType}`,
      title: capitalize(section.sectionType),
    };
  });

  return (
    <ContentProvider
      value={{
        id,
        databaseId,
        CaseStudy,
        CaseStudyBuilder,
        featuredImage,
        FeaturedImages,
        nodeType,
        HeroSection,
        PageCptFields,
        PreFooter,
        seo,
        slug,
        status,
        title,
        uri,
      }}
    >
      <Layout>
        <Hero
          data={{
            ...HeroSection,
            ...FeaturedImages,
            featuredImage: featuredImage?.node,
            CaseStudy,
            logoAlignment: "right",
          }}
        />
        <CaseStudyContainer>
          {caseStudySections}
          <CaseStudyFooter
            setVisibility={setVisibility}
            caseStudy={CaseStudy.nextCaseStudy}
          />
          <CaseStudyNavigation
            navItems={caseStudySectionNavItems}
            hidenav={hidenav}
          />
        </CaseStudyContainer>
      </Layout>
    </ContentProvider>
  );
};

export default CaseStudy;
