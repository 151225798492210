import * as React from "react";
import StaticImage from "../../../components/StaticImage";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { PaginationBullet } from "../../../components/PaginationBullet";
import styled from "@emotion/styled";
import SMBCarousel1 from "../../../icons/case-studies/sportmanitoba/carousel1/carousel-1-1.svg";
import SMBCarousel2 from "../../../icons/case-studies/sportmanitoba/carousel1/carousel-1-2.svg";
import SMBCarousel3 from "../../../icons/case-studies/sportmanitoba/carousel1/carousel-1-3.svg";

SwiperCore.use([Pagination]);

const SwiperComponent = styled(Swiper)`
  margin-bottom: 3rem;
`;

const SlideImage = styled(StaticImage)`
  margin: 0 !important;
`;

const BrandishLogoCarousel = () => {
  return (
    <SwiperComponent
      loop={true}
      pagination={{
        clickable: true,
        renderBullet: PaginationBullet,
        bulletClass: "carousel-bullet-gold",
        bulletActiveClass: "carousel-bullet-gold--active",
      }}
    >
      <SwiperSlide>
        <SlideImage image={<SMBCarousel1 />} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideImage image={<SMBCarousel2 />} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideImage image={<SMBCarousel3 />} />
      </SwiperSlide>
    </SwiperComponent>
  );
};

export default BrandishLogoCarousel;
