import * as React from "react";
import BrandishLogoCarousel from "./carousels/BrandishLogosCarousel";
import BrandishTeamQuoteCarousel from "./carousels/BrandishTeamQuoteCarousel";
import FuturpreneurCampaignCarousel from "./carousels/FuturpreneurCampaignCarousel";
import FuturpreneurCampaignStandardsCarousel from "./carousels/FuturpreneurCampaignStandardsCarousel";
import BrandishAgentCarousel from "./carousels/BrandishAgentCarousel";
import MIGCarouselOne from "./carousels/MIGCarouselOne.jsx";
import MIGCarouselTwo from "./carousels/MIGCarouselTwo.jsx";
import SMBCarousel1 from "./carousels/SMBCarousel1.jsx";
import SMBCarousel2 from "./carousels/SMBCarousel2.jsx";
import SMBCarousel3 from "./carousels/SMBCarousel3.jsx";
import SMBCarousel4 from "./carousels/SMBCarousel4.jsx";
import SMBCarousel5 from "./carousels/SMBCarousel5.jsx";
import SMBCarousel6 from "./carousels/SMBCarousel6.jsx";
import SMBCarousel7 from "./carousels/SMBCarousel7.jsx";
import FormResearchCarousel from "./carousels/FormResearchCarousel.jsx";
import FormBrandStrategyCarousel from "./carousels/FormBrandStrategyCarousel.jsx";
import FormVisualIdentityColoursCarousel from "./carousels/FormVisualIdentityColoursCarousel.jsx";
import FormVisualIdentityFontsCarousel from "./carousels/FormVisualIdentityFontsCarousel.jsx";
import GrantParlColorPalette from "./carousels/GrantParkColorPalette.jsx";
import GrantParkSocialAssets from "./carousels/GrantParkSocialAssets.jsx";
import GrantParkInterior from "./carousels/GrantParkInterior.jsx";

/**
 * Object that maps shortcodes to React JSX components
 * @description `key`: name of shortcode
 * @description `value`: React JSX component to render
 * @example shortcode: <Component />
 */
const shortcodeMap = {
  futurpreneurcampaigncarousel: <FuturpreneurCampaignCarousel />,
  futurpreneurcampaignstandardscarousel: (
    <FuturpreneurCampaignStandardsCarousel />
  ),
  brandishlogocarousel: <BrandishLogoCarousel />,
  brandishagentcarousel: <BrandishAgentCarousel />,
  brandishteamquotecarousel: <BrandishTeamQuoteCarousel />,
  migcarouselone: <MIGCarouselOne />,
  migcarouseltwo: <MIGCarouselTwo />,
  smbcarousel1: <SMBCarousel1 />,
  smbcarousel2: <SMBCarousel2 />,
  smbcarousel3: <SMBCarousel3 />,
  smbcarousel4: <SMBCarousel4 />,
  smbcarousel5: <SMBCarousel5 />,
  smbcarousel6: <SMBCarousel6 />,
  smbcarousel7: <SMBCarousel7 />,
  formresearchcarousel: <FormResearchCarousel />,
  formbrandstrategycarousel: <FormBrandStrategyCarousel />,
  formvisualidentitycolourscarousel: <FormVisualIdentityColoursCarousel />,
  formvisualidentityfontscarousel: <FormVisualIdentityFontsCarousel />,
  grantparkcolorpalette: <GrantParlColorPalette />,
  grantparksocialassets: <GrantParkSocialAssets />,
  grantparkinterior: <GrantParkInterior />,
};

export default shortcodeMap;
